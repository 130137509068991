import {
    createSlug,
    LISTING_PAGE_PARAM_TYPE_DRAFT,
    LISTING_PAGE_PARAM_TYPE_NEW,
    LISTING_PAGE_PARAM_TYPE_EDIT,
} from "./urlHelpers";

import { registerExternalSiteVisit } from "./api";

export const NewListingParams = {
    id: '00000000-0000-0000-0000-000000000000',
    slug: LISTING_PAGE_PARAM_TYPE_DRAFT,
    type: LISTING_PAGE_PARAM_TYPE_NEW,
    tab: 'profile',
};

export const PublishedListingModalStorageKey = "PLMSK";

export const ListingFieldsToHideInListingPage = [
    "customer_contact",
];

export const getListingLinkParams = (params) => {
    const {
        listingId,
        listingTitle,
        toProfile,
        isPublished,
    } = params;

    return listingId ? {
        id: listingId.uuid,
        slug: createSlug(listingTitle),
        type: isPublished ? LISTING_PAGE_PARAM_TYPE_EDIT : LISTING_PAGE_PARAM_TYPE_DRAFT,
        tab: toProfile ? 'profile' : 'details',
    } : NewListingParams;
};

export const getCategorySearchParams = ({
    activeTab, listingCategories, selectedValue, professionOptions
}) => {
    if (!selectedValue) return {};

    if (activeTab == "service") {
        const foundItem = professionOptions.find(i => i.label === selectedValue) || {};
        return { pub_profession: foundItem.option };

    } else {
        const mapper = [];
        listingCategories.map(category => {
            mapper.push({ id: category.id, name: category.name, parent: null });
            if (category.subcategories && category.subcategories.length > 0) {
                category.subcategories.map(subcategory => {
                    mapper.push({ id: subcategory.id, name: subcategory.name, parent: category.id });
                });
            };
        });

        const foundItem = mapper.find(i => i.name === selectedValue) || {};
        let params = {};

        if (foundItem.parent) {
            let parent = mapper.find(i => i.id === foundItem.parent);
            params = {
                pub_categoryLevel1: parent.id,
                pub_categoryLevel2: foundItem.id,
            };
        } else {
            params = { pub_categoryLevel1: foundItem.id };
        };

        return params;
    };
};

export const registerClickAndRedirect = async ({
    e,
    url,
    ownerId,
    listingId,
    currentUser,
}) => {
    e.preventDefault();

    if (ownerId !== currentUser?.id?.uuid) {
        const isSharetribeUser = !!currentUser;

        const params = {
            ownerId,
            listingId,
            isSharetribeUser,
        };

        if (isSharetribeUser) {
            params.visitingUserId = currentUser.id.uuid;
        } else {
            const savedStamp = localStorage.getItem(`Visit for ${ownerId}`);
            const stamp = savedStamp ? savedStamp : new Date().getTime().toString();
            params.cookieStamp = stamp;

            if (typeof window !== "undefined") {
                localStorage.setItem(`Visit for ${ownerId}`, stamp);
            };
        };

        await registerExternalSiteVisit(params);
    };

    if (typeof window !== "undefined") {
        window.open(url, '_blank');
    };
};

export const getProfessionsCats = (intl, professions) => {
    const result = {};

    if (professions && Array.isArray(professions) && professions.length > 0) {
        professions.map(prof => {
            const cats = intl.formatMessage({
                id: `System.ProfessionsMap.${prof}`
            });
            result[prof] = cats.split(", ");
        });
    };

    return result;
};
